.dashboard-nav{
    z-index: 999;
background-color: #ffffff;
box-shadow: 0 3px 3px 0px #e3dfdf;
position: fixed;
top: 0;
width: 100%;
}
.dashboard-logo{
    max-width: 185px;
    margin-right: 25px;
}
.dashboard-nav .search-bar .form-control{
    font-size: 12px !important;
    width: 230px !important;
    padding: 8px 10px !important;
}
.scheduled-webinars .slick-slide {
    /* margin-left:20px; */
}
.web-cards{
    background: white;
}
.float-right{
    float: right;
}

.web-cards img{
padding-bottom: 15px;
}
.web-cards p{
    font-size: 12px;
}
.web-cards p span{
    font-weight: 700;
}
section{
    padding-top: 100px;
    padding-bottom: 100px;
}
.sidebar{
   background-color:#e6f7ff !important;
   margin-top: 90px;
}
.content{
    margin-top: 100px;
    padding-top: 20px;
    min-height: 80vh;
}
footer{
    z-index: 1000;
    color: #ffffff !important;
}

.z-webinar-white-logo img {
    max-width: 160px;
    height: auto;
}

.fs-link a {
    padding-right: 20px;
}

.fb-pr {
    position: relative;    
}

.fb-pr .f-t-style2 {
    position: absolute;
    left: 0;
    bottom: 0;
    opacity: .1;   
}


.fb-color {
    background-color: #003147;
}

.f-t-style1 img {
    width: 100%;
}

element.style {
}
.sidebar .sidebar-text, .sidebar .link-arrow, .sidebar .badge, .sidebar .notification-count {
    opacity: 1;
    transition: opacity 0.3s;
}
.nav-link span {
    position: relative;
}
*, *::before, *::after {
    box-sizing: border-box;
}
.sidebar .nav-item .nav-link {
    color: #214c61 !important;
    border: none;
}
.sidebar .nav-item.active > .nav-link{
    color: #214c61 !important;
    background: none !important;
    border: none;
}
.active.nav-item .sidebar .nav-link .sidebar-icon{
    color: #214c61 !important;
}

.active.nav-item {
 font-weight: 700;    
}

.fnt-wt-700 {
    font-weight: 700;       
}

.sidebar .nav-link .sidebar-icon{
    color: #214c61 !important;
}
.sidebar .nav-item .nav-link:hover{
background-color: transparent !important;
font-weight: 700 !important;
}
.react-tabs__tab{
border:none !important;
}
.react-tabs__tab-list{
    border-bottom: none !important;
}
.common-btn{
    padding: 5px 18px;
    font-size: 0.875rem;
    border-radius: 2px;
}

.mngwebinar_timebtns{

}

.mngwebinar_timebtns img{
max-width: 25px;    
}
.googlecalendar{

}
.outlook{

}
.line-height-2{
    line-height: 3;
}
.border-b-1{
    border-bottom:1px solid #707070;
    padding-bottom: 10px;
}
.yahoocalandar{
    min-width: 180px;
}
.webinar_pass input{
border:none !important;
}
.checkbox label{
    padding-top: 16px;
    background: none !important;
    border-radius: none !important;
}

.pt-100{
    padding-top: 8em;
}
.pt-80{
    padding-top: 6em;
}
.mb-10{
    margin-bottom: 2em;
}
.navbar-toggler.btn.btn-primary {
    border: none;
    background-color: #ffffff !important;
}

.navbar-dark .navbar-toggler {
    color: rgb(255 255 255 / 90%);    
}

.text-main {
    font-weight: 800;
    font-size: 1rem;  
    margin-left: 1em;  
}

.navbar-search {
    margin-left: 40px;    
}

#topbarSearch svg {
    color: #5d6990 !important;
    font-size: 19px;
    margin-left: 8px;    
}

.sidebar .nav-link:focus {
    box-shadow: none !important;    
}

.react-datepicker-wrapper input {
    border: 0.0625rem solid #d1d7e0;
    border-radius: 4px;
    padding: 10px 10px 8px;
    max-width: 100px;
    text-align: center;    
}

.sched-webinar-btn{
    padding: 5px 5px !important;
    font-size: 14px;
}
.btn_search {
    width: 100px;
    height: 40px;
    border-radius: 4px;
    font-size: 16px;
    display: flex;
    justify-content: center;
    align-items: center;   
    background-color: #0071bc;
}

.btn_white {
    border: 2px solid #0071bc;
    background-color: #fff !important;
    color: #0071bc;    
}

.btn_white:hover {
    color: #fff !important;
    background-color: #0071bc !important;
}



.txt_rg {
    text-align: right;
}

.mg-web-btn a {
    background-color: #1b4559;
    padding: 6px 10px;
    border-radius: 4px;
    color: #fff !important;   
}

.web-sel-drop select {
    width: 120px;
    padding: 5px 8px;   
}

.dis-non {
    display: none !important;
}

#cust_name input {
    border-radius: 4px;
    padding: 10px;
}

input {
    border-radius: 4px;
    padding: 10px;
}

.com-name-txt {
    color: #0071bc;
    font-weight: 700;   
}

.body {
    background-color: #fff !important;
}

.card {
    border: none !important;
}

.react-tabs__tab--selected {
    font-weight: 700;
}

.react-tabs__tab {
    padding-right: 50px;    
}

.btn-info {
    background-color: #0071bc;
    padding: 5px 15px;
    border-radius: 4px;    
}

.z-btn-lst button {
    padding: 5px 20px;
    border-radius: 4px;
    margin: 0px 10px;    
}

.fw800 {
    font-weight: 800;
}

.logo-slider-secbg{
    background-image: url("/src/assets/img/icons/logo-slider-bg-shape.svg");
    background-position:top right;
    background-repeat: no-repeat;
    padding-top:7rem !important;
    padding-bottom:7rem !important;
}
.webinar-slider-secbg{
    background-image: url("/src/assets/img/icons/slider-left-bg.svg");
    background-position:bottom left;
    background-repeat: no-repeat;
    padding-top:7rem !important;
    padding-bottom:7rem !important;
}
.services-wrapper ul li:before {
    content: '✓';
    border: 2px solid #253251;
    padding: 0px 5px;
    font-size: 12px;
    font-weight: 800;
    color: #0071bc;
    margin-right: 10px;
}

.services-wrapper ul li {
    list-style: none;
    margin-bottom: 10px;
}

.services-wrapper ul {
    padding-left: 0;
}
  

.iconImg img {
    width: 45px;
}

/* .feat img {
    width: 40px;
} */
.feat img {
    width: 100px;
    border: 2px dashed #253251;
    padding: 10px;
}
.feat img:hover {
    width: 100px;
    border: 2px dashed #0490ec;
    padding: 10px;
}
/* .web-cards-inner:hover{
    border: 1px solid #0490ec;
    width: auto;
    cursor: pointer;
    box-shadow: 0 10px 45px 0 rgb(164 164 164 / 30%);
    padding: 15px;
    border-radius: 10px;
  
} */

.feat_text h5{
    font-size:18px;
}

.feat_text p{
    font-size:13px;
}

.clr_sec {
    color: #253251;
}

.clr_primary {
  color: #0071bc;
}
.Service1{
    background:url('../assets/img/bg/service.jpg');
    background-size: cover;
    background-position: center;
}
.bgclr_sec {
    background: #253251;
}

.bgclr_primary {
  background: #0071bc;
}

/* .bgclr_light{
    background:#f3fdff;
} */
.bgclr_light {
    background: #fafafb;
}

.btn-primary {
    border-color: unset;    
}

.l-da-icn {
   max-width: 25px;
}

.vh100 {
    max-height: 100vh;    
}

.dotimg img {
    max-width: 100px;
    position: absolute;
    bottom: 0;  
}

.nb-btm a {
    border-bottom: 1px solid #acacac;
    padding-bottom: 15px;    
}

.s-form label {
    font-weight: 700;
    margin-top: 20px;
}

::-webkit-input-placeholder {
    font-size: 13px;
  }
  ::-moz-placeholder {
    font-size: 13px;
  }
  :-ms-input-placeholder {
    font-size: 13px;
  }
  ::placeholder {
    font-size: 13px;
  }

.s-form input {
    max-width: 400px;
    border: none;
    border-bottom: 2px solid #e3e2e2;
    border-radius: 0px;
    padding: 4px 0px;   
}

.f-radio-btn input {
    margin-right: 10px;
    border: 2px solid #0071bc;
    border-radius: 71px !important;
    background-color: #fff;
    max-width: 16px;
    max-height: 16px;
    min-width: 16px;
    min-height: 16px;
}

/* .social-signup a {
    margin: 0px 10px;
    padding: 6px 17px;
    min-width: 157px;
    color: #fff !important;
    font-weight: 800;
    border-radius: 4px;    
} */

.g-t-title {
    font-size: 14px;
    color: #7e9095c9;    
}

.c-w-shadeo {
    box-shadow: 0 2px 5px rgba(140,152,164,.2)!important;    
}

.nav-link img {
    width: 18px;
}
.web-cards-inner{
    box-shadow: 0 10px 45px 0 rgb(164 164 164 / 30%);
    padding: 15px;
    border-radius: 10px;
}
.web-cards-inner:focus{
    outline: none;
}
#webinar-slide .slick-slide > div:first-child {
   margin:15px;
  }
  #webinar-slide .slick-slide > div:nth-child(2) {
    margin:15px;
   }
.dashboard-footer {
    background-color: #262B40; 
    margin-top: 2em; 
}

.dashboard-footer .row {
    width: 98%; 
}

.dash-cal-img {
    max-width: 25px;
    margin-right: 10px;    
}

.navbar-nav .icon img {
    min-width: 22px !important;
}

.icn-w40 img {
    width: 50px;
}
.mb-30{
    margin-bottom: 1.5em;
}
.mb-20{
    margin-bottom: 1em;
}
.fb-clr {
    background-color: #4267B2;
    padding: 6px 20px;
    color: #fff !important;
    font-weight: 800;
    border-radius: 4px;
    margin: 0px 1px;
    white-space: nowrap;
}

.lnkIn-clr {
    background-color: #0e76a8;
    padding: 6px 20px;
    color: #fff !important;
    font-weight: 800;
    border-radius: 4px;
    margin: 0px 1px;
    white-space: nowrap;
}

.google-clr {
    background-color: #DB4437;
    padding: 6px 25px;
    color: #fff !important;
    font-weight: 800;
    border-radius: 4px;
    margin: 0px 1px;
    white-space: nowrap;
}


.f-radio-btn label {
    margin: 0px;
    font-size: 13px;
    font-weight: 300;
}

.title-h1 {
    font-size: 38px;
    margin-bottom: 0px;    
}

.daimg img {
    width: 25px;
    margin-top: 50px;
}

.no-box-shadow {
    box-shadow: none !important;
    /* background-color: #ffffff00 !important;   */
}

.hm-static a {
    font-weight: 600;
}

.d-login svg {  
    margin-right: 5px;
    font-size: 14px;
    color: #ffffff;
}
.d-signup svg {  
    margin-right: 5px;
    font-size: 14px;
    color: #ffffff;
}
.footer ul li a{
    color: #ffffff;
}
.navbar-light .navbar-nav .nav-link {
    color: rgba(46, 54, 80, 0.9);
    font-weight: 900;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
    color: #073a88;
}
.bg-white {
    background-color: #e6f1fd !important;
}
a.d-login.sign-btn.nav-link {
    color: #ffffff;
    font-size: 15px;
    display: inline-block;
    margin-left: 0.35rem;
    padding: 8px;
    border-radius: 44px;
    padding-left: 18px;
    padding-right: 25px;
    text-align: center;
    margin-top: 0em;
    background: #0071bc;
}
a.d-login.sign-btn.nav-link:hover{
color: #ffffff;
}
a.d-signup.signup-btn.nav-link:hover {
    color: #ffffff;
}
a.d-signup.signup-btn.nav-link {
    color: #ffffff;
    font-size: 15px;
    display: inline-block;
    margin-left: 0.35rem;
    padding: 8px;
    border-radius: 44px;
    padding-left: 25px;
    padding-right: 25px;
    text-align: center;
    margin-top: 0em;
    background-color: #000000;    
}

.btn-outline-white:hover {
    color: #0071bc;
    background-color: #ffffff;
    border-color: #0071bc;
}

.z-cent-btn {
    display: flex;
    justify-content: flex-end;
    align-items: center;    
}

.btn-lp a{
    background-color: #0071bc;
    padding: 10px 30px;
    border-radius: 4px; 
    color: #fff !important;   
}

.landstyle-bg {
    background-image: url("/src/assets/img/icons/bg-plain.svg");
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
}

.host-bg {
    background-image: url("/src/assets/img/icons/bg-plain-style1.svg");
    background-position: inherit;
    background-repeat: no-repeat;
    background-size: auto;  
}

.aic {
    align-items: center;
}

.newslett_txt input {
    background-color: #f3fcff;
    border-radius: 50px;
    padding: 10px 45px 10px 25px;    
}

.partnerImg img {
    max-width: 150px;
    margin: 0px 25px;    
}

.pricing-list {
    padding: 40px 50px;
    border-radius: 26px;
    color: #253251;
    margin: 0px;   
}

.p-l-bgclr1 {
    background-color: #d7f7ff;   
}

.p-l-bgclr2 {
    color: #fff;
    background-color: #0071bc; 
}

.p-l-bgclr2 hr {
    color: #fff !important;
}

.price-plan {
    position: relative; 
}

.pricing-list hr {
    height: 2px;
    color: #253251;
    opacity: inherit;
    width: 40px;
}

.reset-pwd-wrapper .max-w-500 {
    max-width: 500px;
    margin: auto;    
}

.reset-pwd-wrapper input {
    padding: 10px 0px;
    border: none;
    border-bottom: 2px solid #dddddd;
    border-radius: 0px;    
}

.btn-reset {
    background-color: #0071bc;
    border: none;
    border-radius: 4px;
    padding: 10px 25px;
    margin-top: 30px;    
}

.lgn-lnk {
    margin-top: 30px;
}

.lgn-lnk a {
    color: #0071bc !important;
}

.max-wth-700 {
    max-width: 700px;
}

.max-w-900 {
    max-width: 900px;
}

.p-l-bgclr2 .p-p-hrs {
    color: #ffffff9e;
}

.business-plan {
    position: relative;
}

.pl-bmark {
    width: 56px;
    top: 0;
    position: absolute;
    left: 35px;
}

.pric-star-lst img {
    width: 40px;
    padding: 0px 5px;
}

.pric-star-lst {
    text-align: right;
}

.p-a-title {
    font-weight: 700;
    letter-spacing: 3px;    
}

.p-a-nos {
    font-size: 52px;
    margin-bottom: 10px;
    font-weight: 700;    
}

.p-a-team {
    font-weight: 700;
    margin-bottom: 25px;    
}

.p-p-l {
    font-weight: 700;
    font-size: 30px;    
}

.p-p-hrs {
    font-size: 18px;
    color: #2532519e;    
}

.new-btn {
    border-radius: 50px;
    padding: 10px 25px;
    margin-left: -40px;
    z-index: 9;    
}

.btn-pricing {
    background-color: #fff;
    color: #0071bc !important;
    font-weight: 600;
    padding: 12px 40px;
    border-radius: 4px;
}

.fnt-sz-26 {
    font-size: 26px;
}

.verify-user-wrapper {
    border: 1px solid #949496;
    max-width: 280px;
    padding: 20px 0px;
    border-radius: 8px;
}

.wcardhvr:hover {
    box-shadow: 0 2px 5px rgba(140,152,164,.2)!important;
    border-radius: 16px;    
}

.banner-text-center{
    height: 100vh;
    /* display: flex; */
    justify-content: center;
}

.landing-bg{
    background:url('../assets/img/bg/banner.jpg');
    background-size: cover;
    background-position: center;
}

.logo-slider-secbg img {
    background-color: #fff;
}

@media screen and (max-width: 767px) {
    .dashboard-nav {
        display: none;
    }
  
    .pt-80 {
        padding-top: 1em;
    }
    .pt-100 {
        padding-top: 1em;
    }

    .content {
      margin-top: 0px;        
    }

    .btn_s_wrapper {
        text-align: center;
        margin-top: 25px;
    }

    .btn_search.btn-info {
        margin: auto;    
    }
    .heading1 h1,h2,p{
        text-align: center;
    }
    .z-webinar-white-logo img {
        max-width: 160px;
        height: auto;
        margin: auto;
    }
    .z-cent-btn {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 30px;
    }
    .form1{
        margin:auto;
        justify-content: center;
    }
    .px-3.px-lg-4.py-4.feat_text{
        text-align: center;
    }
    .img1 img{
        width: 50%;
        
    }
    .d-flex.align-items-center.img1.row{
        text-align: center;
    }
    .whyusform{
        
            text-align: center;
            margin: auto;
            justify-content: center;
        
    }
    .sched-webinar-btn{
        margin-top: 10px !important;
   margin-bottom: 10px !important;
        
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1200px) {
    a.d-signup.signup-btn.nav-link {
        width: 50%;
    }
    a.d-login.sign-btn.nav-link {
        margin-bottom: 5px;
        width: 70%;
    }
  }
  @media only screen and (min-width: 672px) and (max-width: 992px) {
    .navbar-collapse {
        width: 50%;
        position: absolute;
        top: 0;
        right: 15px;
        z-index: 1050;
        overflow-y: auto;
        opacity:0;
        margin-top: 5em;
        height: auto !important;
    }
    a.d-login.sign-btn.nav-link {
        margin-bottom: 5px;
        width: 70%;
    }
    a.d-signup.signup-btn.nav-link {
        width: 70%;
    }
    .nb-btm a {
        border-bottom: 1px solid #acacac;
        padding-bottom: 0px !important;
    }
  }
  @media only screen and (min-width: 320px) and (max-width: 672px) {
    .navbar-collapse {
        width: 65%;
        position: absolute;
        top: 0;
        right: 15px;
        z-index: 1050;
        overflow-y: auto;
        opacity:0;
        height: auto !important;
        margin-top: 5em;
    }
    a.d-login.sign-btn.nav-link {
        margin-bottom: 5px;
        width: 70%;
    }
    a.d-signup.signup-btn.nav-link {
        width: 70%;
    }
    .bgclr_primary{
        display: block;
        margin: auto;
    }
    .l-da-icn {
        max-width: 25px;
        display: flex;
        justify-content: center;
        margin: auto;
    }
    .dotimg{
        display: none;
    }
    .landing-bg {
        background:url('../assets/img/bg/mob.jpg');
        background-size: cover;
        background-position: center;
    }
    .services-wrapper ul li{
        font-size: 14px;
    }
    .title-h1 {
        font-size: 25px;
        margin-bottom: 0px;
        font-weight: 700;
    }
    .new-btn{
        padding: 10px;
    }
  }
  @media (min-width: 992px){
   
.navbar-expand-lg .navbar-collapse {
    justify-content: flex-end;
}
  }